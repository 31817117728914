// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-pokemon-tsx": () => import("./../src/templates/pokemon.tsx" /* webpackChunkName: "component---src-templates-pokemon-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-compares-tsx": () => import("./../src/pages/compares.tsx" /* webpackChunkName: "component---src-pages-compares-tsx" */),
  "component---src-pages-favorites-tsx": () => import("./../src/pages/favorites.tsx" /* webpackChunkName: "component---src-pages-favorites-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

